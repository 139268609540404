import React from "react";

const Price = ({ price, discount, special_offer }) => {
    const format = (figure) => figure?.toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });

    return <>
        <p className="price">
            <span className="price-price">
                {format(price)}
            </span>
            {special_offer && discount > 0 && <>
                <span className="price-discount"><s>{format(price + discount)}</s></span>
            </>}
        </p>
    </>;
};

export default Price;
