import React from "react";
import { Icon } from "@iconify/react";

const Counter = ({ min, max, value, onChange, prop, label, showWhenMin, suffix }) => {

    return <div className="d-flex align-items-center gap-2">
        <span className="flex-grow-1">{label}:</span>
        <button className="btn p-1 btn-link" disabled={value == min} onClick={() => onChange(Math.max(value - 1, min), prop)}>
            <Icon icon="carbon:subtract-filled" height="auto" />
        </button>
        <span className="text-center" style={{ minWidth: '40px' }}>
            {value == min && <>{showWhenMin || value}</>}
            {value > min && <>{value} {suffix}</>}
        </span>
        <button className="btn p-1 btn-link" disabled={value == max} onClick={() => onChange(Math.min(value + 1, max), prop)}>
            <Icon icon="carbon:add-filled" height="auto" />
        </button>
    </div>;
};

export default Counter;