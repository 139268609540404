import React, { useContext } from "react";
import MapView from "./MapView";
import ListView from "./ListView";
import { Icon } from "@iconify/react"; //https://icon-sets.iconify.design/
import { useState, useRef, useEffect } from "react";
import { Pluralise } from "../Shared";
import SearchContext from "contexts/searchContext";
import ConfigContext from "contexts/configContext";
import { FavouritesContextProvider } from "contexts/favouritesContext";

export default function VenueList() {
    const { searchValue, loading, results, sorting, setSorting } = useContext(SearchContext);
    const { config } = useContext(ConfigContext);

    const [pricingAvailable, setPricingAvailable] = useState(false);
    const [view, setView] = useState('list');

    const sortOptions = {
        "capacity_asc": "Capacity (Smallest First)",
        "capacity_desc": "Capacity (Largest First)",
        "price_asc": "Price (Low to High)",
        "price_desc": "Price (High to Low)"
    };

    const listRef = useRef(null);

    useEffect(() => {
        listRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, [results]);

    useEffect(() => {
        setPricingAvailable(searchValue.date && searchValue.los)
    }, [searchValue]);

    if (!loading && results.hits?.length === 0)
        return <div ref={listRef} className="d-flex flex-column text-secondary align-items-center justify-content-center mt-5 gap-2 scroll-top-offset">
            <Icon icon="material-symbols:search" className="fs-1" />
            <p>We were unable to find any matching venues. Please adjust or reset the filters to start over.</p>
            <p>We might still be able to help though. Please <a href="/contact">contact us</a> or call us on <a href={`tel:${config.PHONE_NUMBER}`}>{config.PHONE_NUMBER}</a> to see if we can help</p>
        </div>;

    return (
        <>
            <div className="d-flex justify-content-between align-items-center flex-wrap my-1 my-md-3 scroll-top-offset" ref={listRef}>
                <p className="flex-fill col-12 col-md-3 m-0 text-bold">
                    {!loading && <>Found <Pluralise noun="Venue" number={results.hits.length} /></>}
                    {loading && <>Finding Venues...</>}
                </p>
                {view == "list" && <div className="dropdown me-3">
                    <button className="btn dropdown-toggle p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <Icon icon={`bi:sort-down${sorting.endsWith("_asc") ? '-alt' : ''}`} /> {sortOptions[sorting]}</button>
                    <ul className="dropdown-menu">
                        {Object.entries(sortOptions).map(([key, value]) => {
                            return <li key={value}><button className="dropdown-item" disabled={(key.startsWith("price") && !pricingAvailable)} onClick={() => setSorting(key)}>{value}</button></li>
                        })}
                    </ul>
                </div>}
                {view == "list" && <button id="btn-toggle-map-view" className="btn btn-secondary p-2" onClick={() => setView('map')}><Icon icon="tabler:map-2" height="auto" /> Map View</button>}
                {view == "map" && <button className="btn btn-secondary p-2 ms-auto" onClick={() => setView('list')}><Icon icon="bxs:grid" height="auto" /> List View</button>}
            </div>

            <FavouritesContextProvider>
                {view == "list" && <ListView />}
                {view == "map" && <MapView />}
            </FavouritesContextProvider>
        </>
    );
}