import dayjs from "dayjs";

const get = async (url, abortSignal) => {
    const response = await fetch(url, {
        method: 'GET',
        signal: abortSignal
    });

    return await response.json();
};

const AvailabilityService =
{
    checkAvailability: async (venueRef, arrivalDate, los, adults, children, abortSignal) => {
        const response = await fetch('/availability/check', {
            method: 'POST',
            signal: abortSignal,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ref: venueRef,
                date: arrivalDate,
                los: los,
                adults: adults,
                children: children,
                pets: 0
            })
        });

        return await response.json();
    },
    getAvailabilityForVenue: async (venueRef, date, numMonths, abortSignal) => {
        return await get(`/availability/venue/${venueRef}/${date.format("YYYY-MM-DD")}/${date.add(numMonths, 'months').endOf('month').format("YYYY-MM-DD")}`, abortSignal);
    },
    getPriceForVenue: async (venueRef, arrivalDate, departureDate, abortSignal) => {
        return await get(`/availability/venue/${venueRef}/${arrivalDate}/${departureDate}/pricing`, abortSignal);
    }
};

export default AvailabilityService;