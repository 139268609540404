import React, { useState, useEffect, useRef } from "react";
import VenueList from './components/SearchResults';
import AvailabilityCalendar from "./components/AvailabilityCalendar";
import SearchBar, { SearchBarSimple, SearchBarEntry } from "./components/SearchBar";
import Offcanvas from "react-bootstrap/Offcanvas"; //https://react-bootstrap.netlify.app/docs/components/offcanvas#offcanvasheader
import { SearchContextProvider } from "./contexts/searchContext";
import { ConfigContextProvider } from "./contexts/configContext";
import "./App.scss";

export default function App({ data }) {
	const [showSearchSideBar, setShowSearchSideBar] = useState({ show: false, group: '' });

	const toggleSearchSideBar = (show, group) => {
		setShowSearchSideBar({ show: show, group: group || showSearchSideBar.group });
	};

	if (data && data.view == "calendar")
		return <AvailabilityCalendar numMonths={data.numMonths || 2} venueRef={data.venueRef} colSizes="d-flex flex-sm-wrap flex-column flex-md-row justify-content-between gap-3" />;

	return (
		<ConfigContextProvider>
			<SearchContextProvider>
				<Offcanvas show={showSearchSideBar.show} placement="top" onHide={() => toggleSearchSideBar(false)}>
					<Offcanvas.Header closeButton>
						<Offcanvas.Title></Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Body>
						<SearchBar group={showSearchSideBar.group} close={() => toggleSearchSideBar(false)} />
					</Offcanvas.Body>
				</Offcanvas>

				{data && data.view == "search" && <SearchBarEntry edit={toggleSearchSideBar} />}

				{!data?.view && <>
					<div className="bg-primary sticky-top py-2">
						<SearchBarSimple edit={toggleSearchSideBar} />
					</div>
					<div id="venue-list" className="container">
						<VenueList />
					</div>
				</>}

			</SearchContextProvider>
		</ConfigContextProvider>
	);
}