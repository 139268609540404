import React, { useContext } from "react";
import Venue, { VenuePlaceholder } from "../Venue";
import SearchContext from "contexts/searchContext";

const ListView = () => {

    const { loading, results, searchValue } = useContext(SearchContext);
    const sizeClasses = "col-sm-12 col-md-6 col-xl-4 col-huge-3";
    return <div className="row gy-4">
        {loading && <>
            {[...Array(Math.min(6, results.hits?.length || 6))].map((x, i) => {
                return <div key={i} className={sizeClasses}>
                    <VenuePlaceholder />
                </div>;
            })}</>
        }

        {!loading && results.hits?.map((venue) => {
            return <div key={venue.super_control_ref} className={sizeClasses}>
                <Venue venue={venue} price={venue.price} arrival_date={venue.arrival_date?.date} length_of_stay={searchValue.los} discount={venue.discount} special_offer={venue.special_offer} />
            </div>;
        })}
    </div>;
};

export default ListView;