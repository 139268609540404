import React from "react";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween"
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Popover from "react-bootstrap/Popover";
import Calendar from "../../Shared/Calendar";

const MonthView = ({ month, year, selectedStartDate, setSelectedStartDate, selectedEndDate, setSelectedEndDate, calendarData }) => {
    dayjs.extend(isBetween);
    dayjs.extend(isSameOrAfter);

    let startOfMonth = dayjs(`${year}-${month}-01`);
    let endOfMonth = startOfMonth.endOf('month');
    let daysInMonth = endOfMonth.date();

    const onSelectDate = (date) => {
        if (dayjs().isAfter(date, 'day')) return;

        let data = calendarData[date];

        if (!data) return;

        if (!(data.can_checkin || data.can_checkout)) return;

        let isAllowedStartDate = data.can_checkin && data.max_stay > 0;

        let isAllowedEndDate = selectedStartDate && data.can_checkout
            && dayjs(selectedStartDate).add(calendarData[selectedStartDate].max_stay, 'days').isSameOrAfter(date, 'day')
            && dayjs(selectedStartDate).add(calendarData[selectedStartDate].min_stay, 'days').isSameOrBefore(date, 'day');

        if (selectedStartDate) {
            if (date == selectedStartDate) {
                setSelectedStartDate();
                setSelectedEndDate();
                return;
            }
            //Move the arrival date forward
            if (selectedEndDate || dayjs(date).isBefore(selectedStartDate, 'day')) {
                if (isAllowedStartDate) {
                    setSelectedStartDate(date);
                    setSelectedEndDate();
                }
            } else if (isAllowedEndDate) {
                setSelectedEndDate(date);
            }

        } else { //No start date selected yet
            if (isAllowedStartDate)
                setSelectedStartDate(date);
        }
    };


    return <>
        <div className="d-flex flex-fill flex-column my-2">
            <h5 className="text-center">{startOfMonth.format("MMM, YYYY")}</h5>
            <Calendar year={year} month={month}>
                {[...Array(daysInMonth)].map((e, i) => {
                    let date = startOfMonth.add(i, 'days');
                    let data = calendarData[date.format("YYYY-MM-DD")];

                    let classNames = [`${data?.is_available ? 'available' : 'un-available'}`];

                    let isBeforeToday = date.isBefore(dayjs(), 'day');
                    let isAfterSelectedStartDate = selectedStartDate && date.isAfter(selectedStartDate);
                    let isBeforeSelectedStartDate = selectedStartDate && date.isBefore(selectedStartDate);

                    //Based on the current selected start date
                    let maxStay = calendarData[selectedStartDate]?.max_stay;
                    let minStay = calendarData[selectedStartDate]?.min_stay;
                    let isOutsideMaxStay = date.isAfter(dayjs(selectedStartDate).add(maxStay, 'days'));
                    let isOutsideMinStay = date.isBefore(dayjs(selectedStartDate).add(minStay, 'days'));

                    let message = 'Select as the start date';

                    //If the date is within the selected range, add the selected class
                    if (selectedStartDate == date.format("YYYY-MM-DD") || selectedEndDate == date.format("YYYY-MM-DD")
                        || (selectedEndDate && date.isBetween(selectedStartDate, selectedEndDate)))
                        classNames.push('selected');

                    if (selectedStartDate == date.format("YYYY-MM-DD"))
                        classNames.push('start');
                    if (selectedEndDate == date.format("YYYY-MM-DD"))
                        classNames.push('end');

                    //If in the past
                    if (isBeforeToday) {
                        classNames.push('past');
                        message = 'This date has passed';
                    }

                    //Allow selection?
                    if (selectedStartDate) {
                        if (data?.can_checkout && (
                            (!isOutsideMaxStay && isAfterSelectedStartDate)
                            && (!isOutsideMinStay && isAfterSelectedStartDate)))
                            classNames.push('selectable');
                        else {
                            message = "Not available for check-out";
                            classNames.push('un-selectable');
                        }
                    } else {
                        if (data?.max_stay > 0 && data?.can_checkin) {
                            classNames.push('selectable');
                        } else {
                            message = "Not available for check-in";
                            classNames.push('un-selectable');
                        }
                    }

                    if (selectedStartDate && !selectedEndDate)
                        if (isAfterSelectedStartDate)
                            if (isOutsideMaxStay)
                                message = 'Not available for this length of stay';
                            else if (isOutsideMinStay)
                                message = 'Would not meet minimum stay requirement';
                            else
                                message = 'Select as departure date';
                        else if (isBeforeSelectedStartDate)
                            message = 'Move the start date to here';
                        else if (isAfterSelectedStartDate)
                            if (isOutsideMaxStay)
                                message = 'Move the start date to here';
                            else
                                message = 'Move the departure date to here';


                    if (false) //when debugging
                        message = `<span>Checkin: ${data?.can_checkin ? 'Yes' : 'No'}<br/> Checkout: ${data?.can_checkout ? 'Yes' : 'No'}<br/> Max: ${data?.max_stay} days<br/>Min: ${data?.min_stay} days<br/>${classNames}</span><p class="py-2">${message}</p>`;

                    return <Calendar.Day day={date.date()} classnames={classNames} onClick={e => { onSelectDate(date.format("YYYY-MM-DD")) }} />;

                    //Disable tooltips for now
                    return <OverlayTrigger key={i}
                        placement="top"
                        overlay={<Popover><Popover.Body dangerouslySetInnerHTML={{ __html: message }}></Popover.Body></Popover>}
                    >
                        <Calendar.Day day={date.date()} classnames={classNames} onClick={e => { onSelectDate(date.format("YYYY-MM-DD")) }} />
                    </OverlayTrigger>
                })}
            </Calendar>
        </div>
    </>
};

export default MonthView;