import React, { createContext, useState, useEffect } from 'react';

const localStorageKey = 'FavouritedVenues';
const FavouritesContext = createContext({});

export const FavouritesContextProvider = ({ children }) => {
    const [favouritesValue, setFavouritesValue] = useState((localStorage.getItem(localStorageKey) || '').split(','));

    const toggleFavourite = (id) => {
        if (isFavourited(id))
            setFavouritesValue(favouritesValue => favouritesValue.filter(x => x != id));
        else
            setFavouritesValue(favouritesValue => [...favouritesValue, id]);
    };

    useEffect(() => {
        localStorage.setItem(localStorageKey, favouritesValue.join(','));
    }, [favouritesValue]);

    const isFavourited = (id) => {
        return favouritesValue.includes(id);
    };

    return (
        <>
            <FavouritesContext.Provider value={{ isFavourited, toggleFavourite }}>
                {children}
            </FavouritesContext.Provider>
        </>
    );
};

export default FavouritesContext;