import React from "react";
import { Pluralise } from "../index";
export default function Select({ name, value, onChange, min, max, suffix }) {
    const options = Array.from({ length: max - min + 1 }, (_, i) => min + i);

    return (
        <select className="form-select" defaultValue={value} name={name} onChange={onChange}>
            {options.map((option, index) => (
                <option key={index} value={option}>
                    <Pluralise number={option} noun={suffix} />
                </option>
            ))}
        </select>
    );
}