import React, { forwardRef } from "react";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek"

import "./calendar.scss";
const Calendar = ({ month, year, children }) => {
    dayjs.extend(isoWeek);

    let startOfMonth = dayjs(`${year}-${month}-01`);

    let endOfMonth = startOfMonth.endOf('month');

    let daysFromPrevMonth = startOfMonth.isoWeekday() - 1;
    let daysFromNextMonth = 7 - endOfMonth.isoWeekday();
    let weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    return <div className="calendar">
        <ul className="weekdays">
            {weekdays.map((day, i) => { return <li className="ratio ratio-1x1" key={day}><span>{day}</span></li> })}
        </ul>
        <ul className="calendar">
            {[...Array(daysFromPrevMonth)].map((e, i) => { return <Calendar.Day className="other-month" key={`p-${i}`} /> })}
            {children}
            {[...Array(daysFromNextMonth)].map((e, i) => { return <Calendar.Day className="other-month" key={`b-${i}`} /> })}
        </ul>
    </div>;
}

Calendar.Day = forwardRef(function Day(props, ref) {
    let classes = ["ratio", "ratio-1x1"].concat(props.classnames || []);
    return <li ref={ref} className={classes.join(" ")} role="button" tabIndex={0} {...props}><span>{props.day}</span></li>;
});

export default Calendar;