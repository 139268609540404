import React, { useState, useContext } from "react";
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import Favourite from '../Favourite';
import { BookNow, Pluralise, Price } from 'components/Shared';
import AvailabilityCalendar from 'components/AvailabilityCalendar';
import { Icon } from '@iconify/react';
import Placeholder from 'react-bootstrap/Placeholder';
import ConfigContext from "contexts/configContext";
import SearchContext from "contexts/searchContext";
import styles from './venue.scss';

const IconWithCount = ({ icon, label, count }) => {
    return <>
        <span className="icon-with-count flex-column flex-xxl-row">
            <Icon icon={icon} height="auto" />
            <span>
                <Pluralise noun={label} number={count} />
            </span>
        </span>
    </>;
};

const VenuePlaceholder = () => {
    return <div className="venue-listing text-primary">
        <Placeholder as="div" className="venue-img ratio ratio-4x3" animation="wave">
            <Placeholder xs={12} style={{ width: '100%' }} />
        </Placeholder>
        <div className="venue-listing-body">
            <Placeholder as="div" className="d-flex justify-content-between" animation="glow">
                <Placeholder as="h3" className="mb-1" xs={10} />
                <Placeholder xs={1} size="xs" />
            </Placeholder>
            <Placeholder as="p" size="sm" animation="glow">
                <Placeholder className="mt-0 fs-6" xs={6} />
            </Placeholder>
            <div className="d-flex gap-4 flex-wrap justify-content-between">
                {Array.from({ length: 3 }).map((_, idx) =>
                    <Placeholder as="div" className="icon-with-count flex-fill flex-column flex-xl-row" key={idx} animation="glow">
                        <Placeholder as={Icon} icon="tabler:square-filled" height="auto" />

                        <Placeholder className="flex-fill w-100" />
                    </Placeholder>
                )}
            </div>
            <Placeholder as="p" animation="glow">
                <Placeholder xs={12} />
                <Placeholder xs={12} />
                <Placeholder xs={12} />
                <Placeholder xs={10} />
                <Placeholder size="sm" xs={10} />
            </Placeholder>

            <Placeholder as="div" className="d-flex justify-content-between align-items-center" animation="glow">
                <Placeholder as="p" xs={3} size="sm" />
                <Placeholder.Button variant="primary" xs={4}>
                </Placeholder.Button>
            </Placeholder>
        </div>
    </div>
};

const Venue = ({ venue, price, arrival_date, length_of_stay, discount, special_offer }) => {
    dayjs.extend(advancedFormat);
    const { config } = useContext(ConfigContext);
    const { searchValue } = useContext(SearchContext);

    const [showAvailability, setShowAvailability] = useState(false);

    return (
        <div className={`venue-listing ${showAvailability ? 'expanded' : ''}`}>
            <div className="venue-img ratio ratio-4x3">
                <img src={`${config.BASE_URL}${venue.link}/photos/main`} loading="lazy" />
            </div>
            {special_offer && <div className="special-offer-icon">
                Offer
            </div>}
            <div className="venue-listing-body">
                <div className="d-flex">
                    <h3 className="mb-1 flex-fill">{venue.name}</h3>
                    {false && <Favourite id={venue.super_control_ref} />}
                </div>
                <p className="mt-0 text-muted text-start fs-6">{venue.property.address.town}</p>
                <p className="d-flex gap-1 flex-wrap justify-content-between">
                    <IconWithCount icon="iconoir:group" label="Guest" count={venue.occupancy.combined} />
                    <IconWithCount icon="fluent:bed-24-regular" label="Bedroom" count={venue.property.number_of_bedrooms} />
                    <IconWithCount icon="solar:bath-linear" label="Bathroom" count={venue.property.number_of_bathrooms} />
                </p>
                <p className="flex-fill font-small">{venue.description}</p>

                {arrival_date && <div><em>
                    <strong>{dayjs(arrival_date).format('dddd ')}</strong>
                    {dayjs(arrival_date).format('Do MMM, YYYY')}</em> for {length_of_stay} night{length_of_stay > 1 && "s"}
                </div>}
                <div className="d-flex justify-content-between align-items-end">

                    <p className="m-0"><a target="_blank" href={venue.link}> More Details <Icon icon="solar:arrow-right-up-outline" height="1rem" /></a></p>
                    {price && <>
                        <div className="pricing">
                            <Price price={price} discount={discount} special_offer={special_offer} />
                            <BookNow venueRef={venue.super_control_ref} venueName={venue.name} arrivalDate={arrival_date || searchValue.date} los={searchValue.los} capacity={searchValue.adults} />
                        </div>
                    </>}

                    {!price && <button className="btn btn-outline-primary btn-sm" onClick={() => setShowAvailability(!showAvailability)}>
                        <Icon icon="mdi:calendar" /> {showAvailability ? 'Hide' : 'Show'} Availability
                    </button>}
                </div>

                {showAvailability && <div className="mt-2">
                    <AvailabilityCalendar venueRef={venue.super_control_ref} venueName={venue.name} numMonths="1" colSizes="" />
                </div>}
            </div>
        </div >
    );
};

export { VenuePlaceholder };
export default Venue;