import { Icon } from "@iconify/react";
import React, { useState } from "react";
const Toggle = ({ label, checked, onToggle }) => {
    const [isChecked, setIsChecked] = useState(checked);
    const toggle = () => {
        setIsChecked(!isChecked);
        if (onToggle)
            onToggle(!isChecked);
    };

    return (
        <div className="d-flex align-items-center gap-2" onClick={toggle}>
            <Icon icon={isChecked ? "bi:toggle-on" : "bi:toggle-off"} height="2rem" />
            {label}
        </div>
    );
};
export default Toggle;