import React, { useState, useContext, useEffect } from 'react';
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
import Venue from '../Venue';
import Toggle from 'components/Shared/Form/toggle';
import SearchContext from 'contexts/searchContext';
import ConfigContext from 'contexts/configContext';

const containerStyle = {
    width: '100%',
    height: '65vh'
};

const MapView = () => {
    const { results, searchValue } = useContext(SearchContext);
    const { config } = useContext(ConfigContext);
    const [autoZoom, setAutoZoom] = useState(true);

    //TODO move this key to the config context?
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: config.GOOGLE_MAPS_API_KEY
    });

    const [map, setMap] = useState(null);
    const [openInfo, setOpenInfo] = useState(null);

    const onLoad = React.useCallback(function callback(map) {
        setMap(map);
    });

    const setBounds = () => {
        if (!autoZoom) return;

        if (map && results.hits?.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            results.hits?.forEach(venue => {
                bounds.extend({ lat: venue.location[0], lng: venue.location[1] });
            });
            google.maps.event.addListenerOnce(map, "bounds_changed", function () {
                //We don't want to zoom in too far
                this.setZoom(Math.min(this.getZoom(), config.MAP_ZOOM_LEVEL_MIN));
            });
            map.fitBounds(bounds);
        }
    };

    useEffect(() => {
        setBounds();
    }, [results, map]);

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    if (!isLoaded) return <p>Map Loading</p>;

    return <>
        <GoogleMap
            mapContainerStyle={containerStyle}
            mapContainerClassName='map-container'
            center={config.MAP_CENTER}
            zoom={config.MAP_ZOOM_LEVEL}
            onLoad={onLoad}
            onUnmount={onUnmount}>
            {results.hits?.map(venue => {
                return <Marker key={venue.super_control_ref} position={{ lat: venue.location[0], lng: venue.location[1] }} onClick={() => { setOpenInfo(openInfo == venue.name ? '' : venue.name) }}>
                    {openInfo == venue.name &&
                        <InfoWindow options={{ minWidth: 300, maxWidth: 350 }}>
                            <Venue venue={venue} price={venue.price} discount={venue.discount} special_offer={venue.special_offer}
                                arrival_date={searchValue.date} length_of_stay={searchValue.los} party_size={searchValue.capacity} />
                        </InfoWindow>
                    }
                </Marker>
            })}
        </GoogleMap>
        <div className="mt-2">
            <Toggle label="AutoZoom as I search" checked={autoZoom} onToggle={(on) => { setAutoZoom(on); }} />
        </div>
    </>;
};

export default MapView;